import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import { formatLocalesToOptions } from "utils";
import _ from "lodash";

import DraggableModal from "../DraggableModal/DraggableModal";
import { StringAssignField } from "components/form-components";

import classes from "./AddActivityModal.module.scss";
import { imagesURL } from "config";

const AddActivityModal = ({
  show,
  onClose,
  locales,
  activityTemplates,
  onCreate,
  translate
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [activityName, setActivityName] = useState("");
  const [activityNameString, setActivityNameString] = useState("");
  const [oldName, setOldName] = useState(activityName || '');

  const localesOptions = useMemo(() => {
    return formatLocalesToOptions(locales);
  }, [locales]);

  const [activityLocale, setActivityLocale] = useState(() => {
    const [first] = localesOptions;
    return first;
  });

  const addNameByString = ({ text }) => {
    if (activityName){
      setOldName(activityName);
      if (text){
        const name = activityName.split("~")[0]
        setActivityName(`${name}~${translate(text, '', true, false)}`)
        setActivityNameString(`${name}~#${text}`)
      }
      else{
        setActivityName(oldName)
        setActivityNameString(null)
      }
    }
    else{
      setActivityName(text ? translate(text, '', true, false) : oldName)
      setActivityNameString(text || null)
    }         
  }

  return (
    <DraggableModal
      show={show}
      onClose={onClose}
      title={translate(470, "New Activity")}
      withOverlay
    >
      <div className={classes["container"]}>
        <div className={classes.leftColumn}>

          <StringAssignField
            labelClassName={classes["template-descr"]}
            label={translate(1312, "Activity Name (string)")}
            value={!!activityNameString 
            && (parseInt(activityNameString) || parseInt(activityNameString.split("~#")[1]))}
            changeAllHandler={addNameByString}
            withoutAudio
            isTooltip
          />

          <div className={classes.spacer} />

          <label htmlFor="activityName" className={classes["label"]}>
            {translate(1312, "Activity Name:")}
          </label>
          <input
            autoFocus
            type="text"
            className={classes["input"]}
            disabled={!!activityNameString}
            value={activityName}
            onChange={e => setActivityName(_.trimStart(e.target.value))}
          />

          {selectedTemplate && (
            <div className={classes["template-descr"]}>
              <img
                src={`${imagesURL}/TemplateThumbnails/${selectedTemplate.thumbFilename}`}
                alt=""
              />
              <p>{translate(selectedTemplate.explanationString)}</p>
            </div>
          )}
        </div>
        <div className={classes.rightColumn}>
          <div className={classes["template-btns-wrapper"]}>
            {_.chain(activityTemplates)
              .filter(
                templ =>
                  !(templ.availableForWordlists && templ.onlyForWordlists)
              )
              .sortBy(templ => templ.activityTemplateName)
              .map(templ => (
                <button
                  className={classnames(classes["template-btn"], {
                    [classes["active"]]:
                      !!selectedTemplate && selectedTemplate._id === templ._id
                  })}
                  key={templ._id}
                  onClick={() => setSelectedTemplate(templ)}
                >
                  {translate(templ.nameString, templ.activityTemplateName)}
                </button>
              ))
              .value()}
          </div>

          <div className={classes.row}>
            <select 
              className={classes.select} 
              value={activityLocale?.value} 
              onChange={(event) => setActivityLocale(() => {
                const value = event.target.value;
                return localesOptions.find(o => o.value === value);
              })}
            >
              {localesOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <button
              className={classes["save-btn"]}
              disabled={!activityName || !selectedTemplate || !activityLocale}
              onClick={() =>
                onCreate({ activityName, activityNameString, activityLocale, template: selectedTemplate })
              }
            >
              {translate(298, "Save")}
            </button>
          </div>
        </div>
      </div>
    </DraggableModal>
  );
};

AddActivityModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  activityTemplates: PropTypes.array.isRequired
};

const mapStateToProps = ({ status: { translate }, common: { locales } }) => ({ translate, locales });

export default connect(mapStateToProps)(AddActivityModal);
