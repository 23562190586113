import React from 'react';
import PropTypes from 'prop-types';
import AddAudio from '../AddAudio/AddAudio';
import { change } from 'redux-form';

const WordAgsAddAudio = ({ 
  helpFilesPath, 
  locale,
  activeGroupId,
  current,
  onChangeAudio,
  associatedText
}) => {

  return (
    <AddAudio
      disabledButton={associatedText ? false : true}
      saveAfterChange
      path={`${helpFilesPath}`}
      removeOldOnChange
      current={current}
      onChangeHandler={filename => {
        //change('WordAgsForm', `multilangWordsHelp.[${activeGroupId}].helpAudio`, filename ?? '');
        change('WordAgsForm', `multilangWordsHelp.[${activeGroupId}].${locale}.helpAudio`, filename ?? '');
        onChangeAudio(filename);
      }}
      versionType={`immersion_${locale}`}
      associatedText={associatedText}
      isWordAgs={true}
      useFileBucket={false}
    />
  );
};

WordAgsAddAudio.propTypes = {
  locale: PropTypes.string.isRequired,
  activeGroupId: PropTypes.string,
  helpFilesPath: PropTypes.string.isRequired,
  current: PropTypes.string,
  onChangeAudio: PropTypes.func.isRequired,
  associatedText: PropTypes.string,
};

export default WordAgsAddAudio;
