import { combineReducers } from 'redux';
import activity from './activity';
import common from './common';
import editList from './editList';
import clipart from './clipart';
import wordlists from './wordlists';
import tts from './tts';
import activityGroups from './activityGroups';

export default combineReducers({
  activity,
  common,
  editList,
  clipart,
  wordlists,
  tts,
  activityGroups
});
