import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
// import TableToolbar from './components/TableToolbar';
import TablePaginationActions from './components/TablePaginationActions';
import ConfirmDeleteModal from 'components/admin/ConfirmDeleteModal/ConfirmDeleteModal';
import {
  IconButton,
  IconButtonProps,
  TextField,
  Tooltip,
  Checkbox,
} from '@material-ui/core';

import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import { MaterialFaIcon } from 'utils';

const customSelectStyles = {
  dropdownIndicator: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};

export type Filter = {
  value?: any;
  operator: 'equal' | 'contains';
};

export type FilterOption = {
  label: string;
  value: any;
};

export type Column<T extends object = {}> = {
  id: keyof T;
  label: string;
  minWidth?: number;
  align?: 'right';
  disablePadding?: boolean;
  filterable?: boolean;
  options?: FilterOption[];
  asyncOptions?: (filter: Object, input: string) => Promise<FilterOption[]>;
  toggle?: boolean;
  render?({
    row,
    column,
    value,
  }: {
    row: T;
    column: Column<T>;
    value: any;
  }): JSX.Element;
};

type Action<T> = Omit<IconButtonProps, 'onClick' | 'title'> & {
  title: string;
  icon: string;
  onClick(row: T): void;
};

export type MaterialTableNewProps<T extends object> = {
  loading?: boolean;
  columns: Column<T>[];
  data: T[];
  rowCount: number;
  type: string;
  rowWithName: keyof T;
  onPageChange: (size: number) => void;
  onPageSizeChange: (size: number) => void;
  onRequestSort: (sortBy: keyof T) => void;
  getId?: (row: T) => string | number;
  order?: 'asc' | 'desc';
  orderBy?: keyof T;
  editLink?: string;
  filters: Record<keyof T, Filter>;
  onFilterChange: (changedFilter: Record<keyof T, Filter>) => void;
  editHandler?: (row: T) => void;
  deleteHandler?: (row: T) => void;
  actions?: Action<T>[];
  noPaper?: boolean;
};

export default function MaterialTableNew<T extends Record<string, any>>({
  data,
  actions,
  loading,
  columns,
  rowCount,
  order,
  orderBy,
  type,
  rowWithName,
  noPaper,
  onRequestSort,
  onPageChange,
  onPageSizeChange,
  filters,
  editLink,
  onFilterChange,
  editHandler,
  deleteHandler,
  getId = (row: any) => row._id,
}: MaterialTableNewProps<T>) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [localFilter, setLocalFilter] = React.useState(filters);
  const [itemToDelete, setItemToDelete] = React.useState<T>();

  useEffect(() => {
    setLocalFilter(filters);
  }, [filters]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newSize = +event.target.value;
    setRowsPerPage(newSize);
    onPageSizeChange(newSize);
    setPage(0);
  };

  const filterChangeHandler = (prop: keyof T, value: any) => {
    const newValue = {
      ...filters,
      [prop]: { value: value !== '' ? value : undefined },
    };
    setLocalFilter(newValue);
    onFilterChange(newValue);
  };

  const handleCloseModal = () => {
    setItemToDelete(undefined);
  };

  const WrapperComponent = noPaper ? React.Fragment : Paper;
  return (
    <WrapperComponent  {...(noPaper
      ? {}
      : {
          className: classes.root,
          elevation: 4,
        })}>
      <ConfirmDeleteModal
        isOpen={!!itemToDelete}
        handleClose={handleCloseModal}
        handleDelete={() => {
          deleteHandler && deleteHandler(itemToDelete!);
          handleCloseModal();
        }}
        text={`${type} - ${itemToDelete && itemToDelete[rowWithName]}`}
      />

      <TableContainer className={classes.container}>
        {loading && (
          <div className={classes.progressBar}>
            <LinearProgress />
          </div>
        )}

        {/* <TableToolbar count={10} globalFilter={''} setGlobalFilter={() => ''} /> */}

        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              {columns.map((column: Column<T>) => (
                <TableCell
                  key={String(column.id)}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  padding={column.disablePadding ? 'none' : 'default'}
                  sortDirection={orderBy === column.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={() => onRequestSort(column.id)}
                  >
                    {column.label}

                    {orderBy === column.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>

                  {!column.filterable && (
                    <div>
                      <TextField
                        type="search"
                        size="small"
                        value={localFilter[column.id]?.value || ''}
                        onChange={(e) =>
                          filterChangeHandler(column.id, e.target.value)
                        }
                      />
                    </div>
                  )}

                  {!!(column.options && column.options.length) && (
                    <div>
                      <Select
                        placeholder=""
                        styles={customSelectStyles}
                        onChange={(e) =>
                          filterChangeHandler(column.id, e?.value)
                        }
                        isClearable
                        value={column.options.find(
                          (o) => o.value === localFilter[column.id]?.value
                        )}
                        options={column.options}
                      />
                    </div>
                  )}

                  {!!column.asyncOptions && (
                    <div>
                      <AsyncSelect
                        placeholder=""
                        styles={customSelectStyles}
                        // cacheOptions
                        defaultOptions
                        isClearable
                        onChange={(e) =>
                          filterChangeHandler(column.id, e?.value)
                        }
                        loadOptions={column.asyncOptions.bind(
                          null,
                          localFilter
                        )}
                      />
                    </div>
                  )}

                  {column.toggle && (
                    <div>
                      <Checkbox
                        color="primary"
                        checked={!!localFilter[column.id]?.value}
                        onChange={
                          (e) =>
                            filterChangeHandler(
                              column.id,
                              e.target.checked || undefined
                            ) // clear filter when not selected
                        }
                      />
                    </div>
                  )}
                </TableCell>
              ))}

              <TableCell padding="none" />
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map((row) => {
              return (
                <TableRow hover tabIndex={-1} key={getId(row)}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={String(column.id)} align={column.align}>
                        {typeof column.render === 'function'
                          ? column.render({ value, column, row })
                          : value}
                      </TableCell>
                    );
                  })}

                  <TableCell
                    component="td"
                    scope="row"
                    padding="none"
                    className={classes.nowrap}
                  >
                    {actions?.map(({ title, icon, onClick, ...rest }) => (
                      <Tooltip title={title} key={title}>
                        <IconButton onClick={() => onClick(row)} {...rest}>
                          <MaterialFaIcon icon={icon} />
                        </IconButton>
                      </Tooltip>
                    ))}

                    {editLink && (
                      <Link to={`${editLink}/${row._id}`}>
                        <Tooltip title="Edit">
                          <IconButton aria-label="Edit">
                            <MaterialFaIcon icon="pencil-alt" />
                          </IconButton>
                        </Tooltip>
                      </Link>
                    )}

                    {editHandler && (
                      <Tooltip title="Edit">
                        <IconButton
                          aria-label="Edit"
                          onClick={() => editHandler(row)}
                        >
                          <MaterialFaIcon icon="pencil-alt" />
                        </IconButton>
                      </Tooltip>
                    )}

                    {deleteHandler &&
                    !row.alias && ( // if item has alias in code, then in can't be deleted
                        <Tooltip title="Delete">
                          <IconButton onClick={setItemToDelete.bind(null, row)}>
                            <MaterialFaIcon icon="trash-alt" />
                          </IconButton>
                        </Tooltip>
                      )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        className={classes.pagination}
        count={rowCount}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </WrapperComponent>
  );
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    height: '70vh',
    minHeight: 440,
    position: 'relative',
    flexGrow: 1,
  },
  progressBar: { position: 'absolute', top: 0, width: '100%', zIndex: 10 },
  pagination: { borderTop: '1px solid rgba(224, 224, 224, 1)' },
  table: {},
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  nowrap: {
    whiteSpace: 'nowrap',
    textAlign: 'right',
  },
});
