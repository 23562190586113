import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { showStats } from 'actions/gameActions';
import { GO_BACK, HELP, SPEAK, NEXT, PRINT } from 'consts/buttons';
import { toggleAudio, stopAudio } from 'actions/audioActions';
import { activitiesURL, activitiesKey } from 'config';
import { validateProblem } from './readingDeluxeHelpers';
import {
  AdaptiveContainer,
  ActivityButtons,
  EbookComponent,
  EbookTimer,
  Instructions
} from 'components/flink-play';
import API from 'api';

class ReadingDeluxe extends Component {
  state = {};

  componentDidMount() {
    document.addEventListener('startGame', this.init);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentPageIdx !== this.state.currentPageIdx) {
      this.onPageChange();
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerInterval);
    document.removeEventListener('startGame', this.init);
  }

  /*init = () => {
    const {
      activity: {
        activity: { _id },
      }
    } = this.props;

    const thisVersion = !this.props.isPlayVersion ? this.props.activity.activity.currentLanguageVersion 
    : {version: 'immersion', locale: this.props.solutionLocale.code};
    const versionType = thisVersion && thisVersion.version && thisVersion.locale ? `${thisVersion.version}_${thisVersion.locale}` : "";    
    const gameData = versionType 
    ? this.props.activity.data.languageVersionData?.[versionType]
    : this.props.activity.data.gameData;
    const { problems } = gameData;

    const validPages = _.filter(problems, validateProblem);
    const assetsFolder = versionType ? `${activitiesURL}/${_id}/languageVersionData/${versionType}/`
    : `${activitiesURL}/${_id}/gamedata/`;

    this.setState({
      timerStarted: false,
      spentTime: 0,
      assetsFolder,
      currentPageIdx: 0,
      pages: validPages,
      versionType,
      gameData
    });
  };*/

  init = () => {
    const {
      activity: {
        activity: { _id },
        data,
      },
    } = this.props;
  
    console.log('Activity Data:', data);
  
    const thisVersion = !this.props.isPlayVersion
      ? this.props.activity.activity.currentLanguageVersion
      : { version: 'immersion', locale: this.props.solutionLocale.code };
  
    console.log('This Version:', thisVersion);
  
    const versionType =
      thisVersion && thisVersion.version && thisVersion.locale
        ? `${thisVersion.version}_${thisVersion.locale}`
        : "";
  
    console.log('Version Type:', versionType);
  
    let gameData = null;
    let assetsFolder = null;
  
    if (versionType && data.languageVersionData?.[versionType]) {
      gameData = data.languageVersionData[versionType];
      assetsFolder = `${activitiesURL}/${_id}/languageVersionData/${versionType}/`;
      console.log('Using immersion data:', gameData);
    } else if (data.gameData) {
      gameData = data.gameData;
      assetsFolder = `${activitiesURL}/${_id}/gamedata/`;
      console.log('Fallback to gamedata:', gameData);
    }
  
    if (!gameData || !gameData.problems) {
      console.error('Game Data or Problems missing:', gameData);
      return;
    }
  
    const validPages = _.filter(gameData.problems, validateProblem);
  
    this.setState({
      timerStarted: false,
      spentTime: 0,
      assetsFolder,
      currentPageIdx: 0,
      pages: validPages,
      versionType,
      gameData,
    });
  };
  

  showPrevPage = () => {
    const { currentPageIdx } = this.state;
    const isFirstPage = currentPageIdx === 0;

    if (!isFirstPage) {
      this.setState({ currentPageIdx: currentPageIdx - 1 });
    }
  };

  showNextPage = () => {
    const { currentPageIdx, pages } = this.state;
    const isLastPage = pages.length === currentPageIdx + 1;

    if (!isLastPage) {
      this.setState({ currentPageIdx: currentPageIdx + 1 });
    }
  };

  onPageChange = () => {
    stopAudio();
    // this.playPageAudio();
  };

  getCurrentPage = () => {
    const { pages, currentPageIdx } = this.state;
    if (!pages) return null;

    const page = pages[currentPageIdx];
    if (!page) return null;

    if (page.textFrom) {
      const relatedPage = _.find(pages, { id: page.textFrom });

      if (relatedPage) {
        return { ...page, text: relatedPage.text };
      }
    }

    return pages[currentPageIdx];
  };

  playPageAudio = () => {
    const currentPage = this.getCurrentPage();
    if (!currentPage) return;
    const { audio } = currentPage;
    if (!audio) return;

    const { assetsFolder } = this.state;

    toggleAudio(assetsFolder + audio);
  };

  startTimer = () => {
    this.setState({ timerStarted: true });
    this.timerInterval = setInterval(this.increaseTimer, 1000);
  };

  stopTimer = () => {
    const { timerStarted } = this.state;
    if (!timerStarted) return;

    clearInterval(this.timerInterval);
    this.setState({ timerStarted: false }, this.showResults);
  };

  increaseTimer = () => {
    this.setState(state => ({
      spentTime: state.spentTime + 1
    }));
  };

  openPdf = () => {
    const {
      activity: { activity }
    } = this.props;

    const {gameData, versionType} = this.state;
    const pdf = gameData ? gameData.pdf : '';
    if (!pdf) return;

    const key = versionType ? `${activitiesKey}/${activity._id}/languageVersionData/${versionType}/${pdf}` 
    : `${activitiesKey}/${activity._id}/gamedata/${pdf}`;
    API.storage.openPdf(key, true);
  };

  showResults = () => {
    const { showStats } = this.props;
    const { spentTime, pages, currentPageIdx } = this.state;

    const currentPage = this.getCurrentPage();

    const isLastPage = pages.length === currentPageIdx + 1;

    const { totalWords, benchmark } = currentPage;
    const speed = Math.round((totalWords / spentTime) * 60);

    this.setState({ spentTime: 0 });

    showStats({
      data: { speed, benchmark },
      ebookBenchmark: {
        benchmark,
        totalWords,
        speed,
        spentTime
      },
      onlyCloseButton: !isLastPage
    });
  };

  render() {
    const {
      assetsFolder,
      currentPageIdx,
      pages,
      spentTime,
      timerStarted,
      gameData 
    } = this.state;
    const pdf = gameData ? gameData.pdf : '';

    const currentPage = this.getCurrentPage();

    const { audio, hasTimer } = currentPage || {};

    const isLastPage = pages && pages.length === currentPageIdx + 1;

    return (
      <>
        <AdaptiveContainer
          smallWidth={100}
          largeWidth={105 + 6}
          style={{
            position: 'absolute',
            left: '50%',
            top: '1%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: hasTimer ? 'space-between' : 'center',
            transform: 'translateX(-50%)'
          }}
        >
          {hasTimer && (
            <EbookTimer
              showStart={!timerStarted}
              startHandler={this.startTimer}
              showStop={timerStarted}
              stopHandler={this.stopTimer}
              spentTime={spentTime}
            />
          )}

         { <Instructions
            problem={currentPage}
            wrapperStyle={{ position: 'static', transform: 'none' }}
          /> }
        </AdaptiveContainer>

        {currentPage && (
        <EbookComponent
            wrapperStyles={{ top: '53%' }}
            // smallWidth={92}
            largeWidth={105}
            page={currentPage}
            assetsFolder={assetsFolder}
          />
        )}

        <ActivityButtons
          buttons={[
            {
              type: GO_BACK,
              onClick: isLastPage && timerStarted ? this.stopTimer : undefined
            },
            {
              type: NEXT,
              dontShow: timerStarted || isLastPage,
              onClick: this.showNextPage
            },
            {
              type: PRINT,
              dontShow: !pdf,
              onClick: this.openPdf
            },
            HELP,
            {
              type: SPEAK,
              dontShow: !audio,
              onClick: this.playPageAudio
            }
          ]}
        />
      </>
    );
  }
}

ReadingDeluxe.propTypes = {
  showStats: PropTypes.func.isRequired
};

export default connect(null, { showStats })(ReadingDeluxe);
