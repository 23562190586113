import API from 'api';
import { axiosAPI, withLoading } from 'utils';

export const fetchActivityGroups = params => dispatch =>
  withLoading(dispatch, () =>
    axiosAPI
      .get('api/flink-make/activity-groups', { params })
      .then(res => res.data)
      .catch(err => {
        console.error(err);
        return err.response.data;
      })
  );

export const getGroupElementsById = id => dispatch =>
  withLoading(dispatch, () => API.activityGroups.getGroupElementsById(id));

export const checkIfGroupsCanBeAdded = (...args) => dispatch =>
  withLoading(dispatch, () =>
    API.activityGroups.checkIfGroupsCanBeAdded(...args)
  );

export const addActivityGroup = data => dispatch =>
  withLoading(dispatch, () =>
    axiosAPI
      .post('api/flink-make/activity-groups', data)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return err.response.data;
      })
  );

export const editActivityGroup = data => dispatch =>
  withLoading(dispatch, () =>
    axiosAPI
      .put('api/flink-make/activity-groups', data)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return err.response.data;
      })
  );

export const deleteActivityGroup = id => dispatch =>
  withLoading(dispatch, () =>
    axiosAPI
      .delete(`api/flink-make/activity-groups/${id}`)
      .then(res => res.data)
      .catch(err => {
        console.error(err);
        return err.response.data;
      })
  );

export const deleteWordGroupFiles = files => dispatch =>
  withLoading(dispatch, () => API.storage.deleteFiles(files));

export const moveToWordAgList = wordAgIds => dispatch =>
  withLoading(dispatch, async () =>
    axiosAPI
      .post('/api/flink-make/activity-groups/move-to-word-ag-list', { wordAgIds })
      .then(res => {
        if (!res.data.success) {
          return console.log(res.data);
        }

        return true;
      })
      .catch(err => {
        console.log(err);
        return false;
      })
  );

export const removeFromWordAgList = wordAgIds => dispatch =>
  withLoading(dispatch, async () =>
    axiosAPI
      .post('/api/flink-make/activity-groups/remove-from-word-ag-list', { wordAgIds })
      .then(res => {
        if (!res.data.success) {
          return console.log(res.data);
        }

        return true;
      })
      .catch(err => {
        console.log(err);
        return false;
      })
  );

export const copyWordlistFiles = (groupId, data) => dispatch =>
  withLoading(dispatch, async () => {
    const result = {};

    return axiosAPI
      .post(`/api/activity-groups/copy-wordlist-files/${groupId}`, data)
      .then(res => {
        if (!res.data) throw new Error("Error copy files");

        result.group = res.data;
        return result;
      })
      .catch(err => {
        console.log(err);
        result.error = err;
        return result;
      });
  });

export const checkGroup = (groups, userId = null) => dispatch =>
  withLoading(dispatch, () =>
    axiosAPI
      .post('api/flink-make/activity-groups/check-group', { groups, userId })
      .then(res => {
        return res.data.groups;
      })
      .catch(err => {
        console.error(err);
        return err.response.data;
      })
  );

export const removeElementsFromWordAg = elementsIds => dispatch =>
  withLoading(dispatch, async () =>
    axiosAPI
      .post('/api/flink-make/activity-groups/remove-elements-from-word-ag', { elementsIds })
      .then(res => {
        if (!res.data.success) {
          return false;
        }
        console.log('removeElementsFromWordAg res', res);
        return res.data.result.length;
      })
      .catch(err => {
        console.log('removeElementsFromWordAg err',err);
        return false;
      })
  );

