import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from 'react-redux';
import _ from 'lodash';

import classes from "./GroupsSwitch.module.scss";
import { withTranslate } from "components/hocs";

class GroupsSwitch extends Component {
  state = {
    activityGroupName: "educationalCategories",
    isSuper: false,
    userPermissions: {}
  };

  componentDidMount() {
    this.getPermissions();
  }

  changePropHandler = (prop, value) => {
    this.setState({ [prop]: value },
      ()=>{
        this.props.clickSwitchHandler(this.state.activityGroupName);
        //this.getPermissions();
      });    
  };

  getPermissions = () => {
    const { auth } = this.props;
    const isSuper = auth.userRole.alias === 'SUPER';
    const userPermissions =
    _.get(
      auth.userRole,
      'permissions[flinkMake][searchAgsTab][actions]'
      ) || {};

    this.setState({ isSuper, userPermissions });
  }

  render() {
    const { translate } = this.props;
    const { activityGroupName, isSuper, userPermissions } = this.state;

    return (
      <div className={classes.wrapper}>
        
        {(isSuper || userPermissions.eduAgs) && <label className={classes.radioGroup}>
          <input
            type="radio"
            checked={activityGroupName === "educationalCategories"}
            value="educationalCategories"
            onChange={e =>
              this.changePropHandler("activityGroupName", e.target.value)
            }
          />
          <span>{translate(1384, "Educational Categories Activity Groups")}</span>
        </label>}

        {(isSuper || userPermissions.menuAgs) && <label className={classes.radioGroup}>
          <input
            type="radio"
            checked={activityGroupName === "menu"}
            value="menu"
            onChange={e =>
              this.changePropHandler("activityGroupName", e.target.value)
            }
          />
          <span>{translate(1385, "Menu Activity Groups")}</span>
        </label>}

        {(isSuper || userPermissions.wordAgs) && <label className={classes.radioGroup}>
          <input
            type="radio"
            checked={activityGroupName === "word"}
            value="word"
            onChange={e =>
              this.changePropHandler("activityGroupName", e.target.value)
            }
          />
          <span>{translate(1821, "Word Activity Groups")}</span>
        </label>}
      
      </div>
    );
  }
}

GroupsSwitch.propTypes = {
  clickSwitchHandler: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }) => ({
  auth
});

export default compose(
  connect(mapStateToProps),withTranslate
)(GroupsSwitch);
