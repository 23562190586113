import { axiosAPI } from "utils";
import _ from "lodash";

import {
  SET_WORDLISTS,
  ADD_WORDLIST,
  EDIT_WORDLIST,
  DELETE_WORDLIST,
  UNDO_DELETE_WORDLIST,
  REMOVE_FROM_MY_WORDLISTS,
  CLEAR_WORDLISTS_DELETE_LIST
} from "actions/types";
import {
  withLoading,
  getTranslateFunc,
  CustomError,
  removeParenthesis
} from "utils";
import {
  validateWordlist,
  getWordPictureFilename
} from "utils/wordlistHelpers";
import { showError, showInfo, showConfirm } from "actions/statusActions";
import ActivityTemplates from "activity-templates";

const filter = "flinkMake/wordlists";

export const fetchWordlists = (params = {}) => dispatch =>
  withLoading(dispatch, async () => {
    try {
      const res = await axiosAPI.get("/api/flink-make/wordlists", { params });

      return res.data;
    } catch (err) {
      console.log(err);
      return [];
    }
  });

export const fetchMyWordlists = () => dispatch =>
  withLoading(dispatch, async () => {
    try {
      const res = await axiosAPI.get("/api/flink-make/wordlists/my-list");

      dispatch({
        filter,
        type: SET_WORDLISTS,
        payload: res.data
      });
    } catch (err) {
      console.log(err);
    }
  });

export const createWordlist = () => dispatch =>
  withLoading(dispatch, async () => {
    try {
      const res = await axiosAPI.post("/api/flink-make/wordlists");

      dispatch({
        filter,
        type: ADD_WORDLIST,
        payload: res.data
      });

      return res.data;
    } catch (err) {
      console.log(err);
    }
  });

export const editWordlist = data => dispatch => {
  dispatch({
    filter,
    type: EDIT_WORDLIST,
    payload: { ...data, wasChanged: true }
  });
};

export const addWordsToWordlist = params => dispatch =>
  withLoading(dispatch, async () => {
    try {
      const res = await axiosAPI.post(
        "/api/flink-make/wordlists/add-words",
        params
      );

      const { wordlist, wordsNotInDB } = res.data;

      dispatch({
        filter,
        type: EDIT_WORDLIST,
        payload: { ...wordlist, wasChanged: true }
      });

      return {
        success: true,
        wordsNotInDB: wordsNotInDB
      };
    } catch (err) {
      console.log(err);

      return {
        success: false
      };
    }
  });

export const moveWordlistsToEditList = wordlistsIds => dispatch =>
  withLoading(dispatch, async () =>
    axiosAPI
      .post("/api/flink-make/wordlists/move-to-edit-list", { wordlistsIds })
      .then(res => {
        if (!res.data.success) {
          console.log(res.data);
          return false;
        }

        return true;
      })
      .catch(err => {
        console.log(err);
        return false;
      })
  );

/**
 * @param {Object}    params
 * @param {Object}    params.wordlist             DB object.
 * @param {String}    params.activityLocale       activity locale code.
 * @param {Array}     params.helpLocale           help locales code.
 * @param {Boolean}   params.wordsWithImages
 */
export const getWords = params => (dispatch, getState) =>
  withLoading(dispatch, async () => {
    const {
      wordlist,
      activityLocale,
      wordsWithImages
    } = params;

    const {
      common: { strings, locales },
      status: { lang: currentLang }
    } = getState();

    const translate = getTranslateFunc(strings, currentLang.code);
    const activityLocaleObj = _.find(locales, { code: activityLocale });
    const { words: originWords } = wordlist;

    let passedWords = [];
    const allAssets = [];
    const allWordsAssets = [];
    
    // 1. Get words with appropriate locale
    return axiosAPI
      .post("/api/flink-make/wordlists/get-words-by-names-and-locale", {
        words: originWords,
        locale: activityLocale
      })
      .then(res => {
        const { data: wordsFromDB } = res;

        if (!wordsFromDB.length) {
          // If no words, show error
          throw new CustomError(
            translate(288, "Words of selected locale was not founded in DB")
          );
        }

        // 2. Check if all origin words have their DB records
        const wordsNotInDB = [];

        originWords.forEach(w => {
          const wordFromDB = _.find(
            wordsFromDB,
            activityLocale === "en" ? { word: w } : { relatedWord: w }
          );

          if (wordFromDB) {
            const wordWithoutParenthesis = {
              ...wordFromDB,
              word: removeParenthesis(wordFromDB.word),
              relatedWord:
                wordFromDB.relatedWord &&
                removeParenthesis(wordFromDB.relatedWord)
            };

            passedWords.push(wordWithoutParenthesis);
          } else {
            wordsNotInDB.push(w);
          }
        });

        if (wordsNotInDB.length) {
          // If some words haven't DB records then show error
          throw new CustomError(
            translate(0, "Following words not found in DB: ") +
              wordsNotInDB.join(", ")
          );
        }

        // 3. Check if all words have images (only if "wordsWithImages" is checked)
        const wordsWithoutImages = [];

        if (wordsWithImages) {
          passedWords.forEach(
            w => !w.hasPicture && wordsWithoutImages.push(w.word)
          );
        }

        // 6. Check if words have assets
        
        passedWords.forEach(word => {
          let wordAudio = "";
          let definitionAudio = "";
          let wordImage = "";
          
          // Get word audio asset
          if (word.wordAudio) {
            const firstLetter = word.word[0].toUpperCase();
            wordAudio = `Audio/Generic/WordsDB/${activityLocaleObj.name}/Words/${firstLetter}/${word.wordAudio}`;
            allAssets.push(
              `Audio/Generic/WordsDB/${activityLocaleObj.name}/Words/${firstLetter}/${word.wordAudio}`
            );

          }

          // Get definition audio asset
          if (word.definitionAudio) {
            const firstLetter = word.definitionAudio[0].toUpperCase();
            definitionAudio = `Audio/Generic/WordsDB/${activityLocaleObj.name}/Definitions/${firstLetter}/${word.definitionAudio}`;
            allAssets.push(
              `Audio/Generic/WordsDB/${activityLocaleObj.name}/Definitions/${firstLetter}/${word.definitionAudio}`
            );
          }

          // Get definition audio asset (word without parenthesis in jpg format)
          if (wordsWithImages && word.hasPicture) {
            const filename = getWordPictureFilename(word);
            wordImage = `Images/WordlistPictures/${filename}`;
            allAssets.push(`Images/WordlistPictures/${filename}`);
          }

          allWordsAssets.push({wordAudio,definitionAudio,wordImage});
        });

        // Send assets for check
        return axiosAPI.post(
          "api/flink-make/wordlists/check-assets",
          allAssets
        );
      })
      .then(response => {
        const missedAssets = response.data
          .filter(res => !res.success)
          .map(res => res.key);

        return Promise.resolve({ words: passedWords, missedAssets, allAssets, allWordsAssets });
      })
      .catch(err => {
        console.log(err);

        if (err.custom) {
          dispatch(showError({ message: err.message }));
        }

        return Promise.resolve(null);
      });
  });

export const transferWordlistsToWordAgs = wordlistsIds => dispatch =>
  withLoading(dispatch, async () =>
    axiosAPI
      .post("/api/flink-make/wordlists/transfer-to-word-ags", { wordlistsIds })
      .then(res => {
        if (!res.data.success) {
          console.log(res.data);
          return false;
        }

        return true;
      })
      .catch(err => {
        console.log(err);
        return false;
      })
  );

const editWordlistsForReal = wordlists => dispatch =>
  withLoading(dispatch, () => {
    const promises = wordlists.map(w => {
      delete w.wasChanged;
      w.isValid = validateWordlist(w);
      return axiosAPI.put("/api/flink-make/wordlists", w);
    });

    return Promise.all(promises)
      .then(() => {
        console.log("Wordlists was successfully saved to db");
      })
      .catch(err => console.log(err));
  });

export const undoDeleteWordlist = () => ({
  filter,
  type: UNDO_DELETE_WORDLIST
});

export const deleteWordlist = wordlist => ({
  filter,
  type: DELETE_WORDLIST,
  payload: wordlist
});

export const removeFromMyWordlists = arrayOfIDs => (dispatch, getState) =>
  withLoading(dispatch, async () => {
    if (!arrayOfIDs || !arrayOfIDs.length) {
      return;
    }

    try {
      await saveWordlistChanges()(dispatch, getState);

      const result = await axiosAPI.post(
        "/api/flink-make/wordlists/remove-from-user-wordlists",
        arrayOfIDs
      );

      if (result.data.success) {
        dispatch({
          filter,
          type: REMOVE_FROM_MY_WORDLISTS,
          payload: arrayOfIDs
        });
      }
    } catch (err) {
      console.error(err);
    }
  });

export const saveWordlistChanges = () => async (dispatch, getState) => {
  const { list, wordlistsToDelete } = getState().flinkMake.wordlists;

  const modifiedWordlists = list.filter(w => w.wasChanged);

  await editWordlistsForReal(modifiedWordlists)(dispatch);
  await deleteWordlistsForReal(wordlistsToDelete)(dispatch);
};

const deleteWordlistsForReal = wordlistsToDelete => dispatch =>
  withLoading(dispatch, async () => {
    if (!wordlistsToDelete.length) return;

    return Promise.all(
      wordlistsToDelete.map(({ _id: id }) =>
        axiosAPI.delete(`/api/flink-make/wordlists/${id}`)
      )
    )
      .then(() => dispatch(_clearDeleteList()))
      .catch(err => {
        console.log(err);
        dispatch(_clearDeleteList());
      });
  });

const _clearDeleteList = () => ({
  filter,
  type: CLEAR_WORDLISTS_DELETE_LIST
});

const _filterWordsByTemplateCriteria = (words, template, wordAgs = false) => {
  const {
    imageRequired,
    hyphenAllowed,
    spacesAllowed,
    maxWordLength
  } = template;

  const filteredWords = words.filter(w => {
    if (!wordAgs) {
      if (imageRequired && !w.hasPicture) {
        return false;
      }
    }
    else {
      if (imageRequired && !w.wordImage) {
        return false;
      }
    }

    const word = removeParenthesis(w.word);

    if (!hyphenAllowed && word.indexOf("-") !== -1) {
      return false;
    }

    if (!spacesAllowed && word.indexOf(" ") !== -1) {
      return false;
    }

    if (maxWordLength && word.length > maxWordLength) {
      return false;
    }

    return true;
  });

  return filteredWords;
};

/**
 * @param {Object}    params
 * @param {Object}    params.wordlist             DB object.
 * @param {String}    params.activityLocale       activity locale code.
 * @param {Array}     params.helpLocale           help locales code.
 * @param {Array}     params.selectedTemplatesIDs Array of Activity Templates IDs.
 * @param {Boolean}   params.wordsWithImages
 */
export const checkWords = params => (dispatch, getState) =>
  withLoading(dispatch, async () => {
    const {
      wordlist,
      activityLocale,
      helpLocale,
      selectedTemplatesIDs,
      wordsWithImages
    } = params;

    const {
      common: { strings, activityTemplates, locales },
      status: { lang: currentLang }
    } = getState();

    const translate = getTranslateFunc(strings, currentLang.code);

    const activityLocaleObj = _.find(locales, { code: activityLocale });
    const helpLocaleObjects = _.filter(locales, loc =>
      helpLocale.includes(loc.code)
    );

    const { words: originWords } = wordlist;

    let passedWords = [];

    const selectedTemplates = selectedTemplatesIDs.map(_id =>
      _.find(activityTemplates, { _id })
    );

    // 1. Get words with appropriate locale
    return axiosAPI
      .post("/api/flink-make/wordlists/get-words-by-names-and-locale", {
        words: originWords,
        locale: activityLocale
      })
      .then(res => {
        const { data: wordsFromDB } = res;

        if (!wordsFromDB.length) {
          // If no words, show error
          throw new CustomError(
            translate(288, "Words of selected locale was not founded in DB")
          );
        }

        // 2. Check if all origin words have their DB records
        const wordsNotInDB = [];

        originWords.forEach(w => {
          const wordFromDB = _.find(
            wordsFromDB,
            activityLocale === "en" ? { word: w } : { relatedWord: w }
          );

          if (wordFromDB) {
            const wordWithoutParenthesis = {
              ...wordFromDB,
              word: removeParenthesis(wordFromDB.word),
              relatedWord:
                wordFromDB.relatedWord &&
                removeParenthesis(wordFromDB.relatedWord)
            };

            passedWords.push(wordWithoutParenthesis);
          } else {
            wordsNotInDB.push(w);
          }
        });

        if (wordsNotInDB.length) {
          // If some words haven't DB records then show error
          throw new CustomError(
            translate(0, "Following words not found in DB: ") +
              wordsNotInDB.join(", ")
          );
        }

        // 3. Check if all words have images (only if "wordsWithImages" is checked)
        const wordsWithoutImages = [];

        if (wordsWithImages) {
          passedWords.forEach(
            w => !w.hasPicture && wordsWithoutImages.push(w.word)
          );
        }

        if (wordsWithoutImages.length) {
          // If some words haven't DB records then show error
          throw new CustomError(
            translate(
              0,
              "Following words have not associated image with it: "
            ) + wordsWithoutImages.join(", ")
          );
        }

        // 4. Check if all selected templates has Generating workflow in the code
        const templatesWithoutGeneratingFunc = [];

        selectedTemplates.forEach(template => {
          const TemplateData = ActivityTemplates[template.alias];

          if (!TemplateData.generateActivityFromWords) {
            templatesWithoutGeneratingFunc.push(template.activityTemplateName);
          }
        });

        if (templatesWithoutGeneratingFunc.length) {
          // If some templates dont have generating func -> show error
          throw new CustomError(
            translate(
              263,
              "Following templates dont have generating functions: "
            ) + templatesWithoutGeneratingFunc.join(", ")
          );
        }

        // 5. Validate words for each template
        const validateWordsErrors = [];

        selectedTemplates.forEach(template => {
          const { minWords } = template;

          const filteredWords = _filterWordsByTemplateCriteria(
            passedWords,
            template
          );

          if (filteredWords.length < minWords) {
            validateWordsErrors.push({
              template: template.activityTemplateName,
              message: translate(
                0,
                "Not enough words after filtering by template criteria"
              )
            });

            return;
          }

          const TemplateData = ActivityTemplates[template.alias];

          const result = TemplateData.validateWords(filteredWords, template);

          if (result.error) {
            validateWordsErrors.push({
              template: template.activityTemplateName,
              message: result.error
            });
          }
        });

        if (validateWordsErrors.length) {
          throw new CustomError(
            validateWordsErrors
              .map(err => `${err.template}: ${err.message}`)
              .join("; ")
          );
        }

        // 6. Check if words have assets
        const allAssets = [];

        passedWords.forEach(word => {
          // Get word audio asset
          if (word.wordAudio) {
            const firstLetter = word.word[0].toUpperCase();

            allAssets.push(
              `Audio/Generic/WordsDB/${activityLocaleObj.name}/Words/${firstLetter}/${word.wordAudio}`
            );
          }

          // Get definition audio asset
          if (word.definitionAudio) {
            const firstLetter = word.definitionAudio[0].toUpperCase();

            allAssets.push(
              `Audio/Generic/WordsDB/${activityLocaleObj.name}/Definitions/${firstLetter}/${word.definitionAudio}`
            );
          }

          // Get definition audio asset (word without parenthesis in jpg format)
          if (wordsWithImages && word.hasPicture) {
            const filename = getWordPictureFilename(word);
            allAssets.push(`Images/WordlistPictures/${filename}`);
          }
        });

        selectedTemplates.forEach(template => {
          const filename =
            template.activityTemplateName.split(" ").join("") + ".mp3";

          helpLocaleObjects.forEach(loc => {
            const helpSrc = `Audio/Generic/WordlistActivityHelp/${loc.name}/${filename}`;
            allAssets.push(helpSrc);
          });
        });

        // Send assets for check
        return axiosAPI.post(
          "api/flink-make/wordlists/check-assets",
          allAssets
        );
      })
      .then(response => {
        const missedAssets = response.data
          .filter(res => !res.success)
          .map(res => res.key);

        return Promise.resolve({ words: passedWords, missedAssets });
      })
      .catch(err => {
        console.log(err);

        if (err.custom) {
          dispatch(showError({ message: err.message }));
        }

        return Promise.resolve(null);
      });
  });

/**
 * @param {Object}    params
 * @param {Object}    params.wordlist               DB object.
 * @param {String}    params.activityGroupName      Desired name of activity group
 * @param {String}    params.activityLocale         activity locale code.
 * @param {Array}     params.helpLocale             help locales code.
 * @param {String}    params.defaultLocale          Locale Code. If help locales > 1, then default locale is required
 * @param {Array}     params.selectedTemplatesIDs   Array of Activity Templates IDs.
 * @param {Boolean}   params.includeDefinitionAudio
 * @param {String}    params.wordlistName           String "wordlistName1" OR "wordlistName2"
 * @param {Boolean}   params.wordsWithImages
 */
export const generateActivityGroup = params => (dispatch, getState) =>
  withLoading(dispatch, () => {
    const {
      wordlist, // object
      activityGroupName, // string
      activityLocale, // string locale code
      helpLocale, // string locale code
      defaultLocale,
      selectedTemplatesIDs, // array of IDs
      includeDefinitionAudio, // boolean
      wordlistName // string
      // wordsWithImages // boolean
    } = params;

    const {
      common: { strings, activityTemplates, locales },
      status: { lang: currentLang }
    } = getState();

    const activities = [];
    const translate = getTranslateFunc(strings, currentLang.code);

    const activityLocaleObj = _.find(locales, { code: activityLocale });
    const activityLocaleTranslate = getTranslateFunc(
      strings,
      activityLocaleObj.code
    );

    const helpLocaleObjects = _.filter(locales, loc =>
      helpLocale.includes(loc.code)
    );

    const helpLocaleTranslates = {};

    helpLocale.forEach(
      code => (helpLocaleTranslates[code] = getTranslateFunc(strings, code))
    );

    const isMultiLocaleHelp = helpLocaleObjects.length > 1;

    const selectedTemplates = selectedTemplatesIDs.map(_id =>
      _.find(activityTemplates, { _id })
    );

    let missedAssets = [];

    // 1. Check that activity group name is available
    return axiosAPI
      .get(`/api/flink-make/activity-groups`, {
        params: { name: activityGroupName }
      })
      .then(response => {
        if (response.data.data.length) {
          // Activity Group with that name already exists
          throw new CustomError(
            translate(
              609,
              "The activity Group name is already taken. Use a different name.",
              true
            )
          );
        }

        // 2. Check words
        return checkWords(params)(dispatch, getState);
      })
      .then(results => {
        if (!results) {
          throw new Error(translate(0, "Words didn't pass check"));
        }

        const { words, missedAssets: missed } = results;

        missedAssets = missed;

        if (missedAssets.length) {
          const message = `<b>Missed Assets:</b><br>${missedAssets.join(
            "<br>"
          )}<br><br><strong>Do you want to create activity group anyway? This may cause errors in activity</strong>`;

          const getUserConfirmation = new Promise(function(resolve, reject) {
            dispatch(
              showConfirm({
                bigZIndex: true,
                message: message,
                html: true,
                cb: isConfirmed => (isConfirmed ? resolve(words) : reject())
              })
            );
          });

          return getUserConfirmation;
        } else {
          return Promise.resolve(words);
        }
      })
      .then(words => {
        // Generate gameData for each template
        selectedTemplates.forEach(template => {
          const TemplateData = ActivityTemplates[template.alias];

          const { minWords } = template;

          const filteredWords = _filterWordsByTemplateCriteria(words, template);

          if (filteredWords.length < minWords) {
            throw new CustomError(
              translate(
                0,
                "Not enough words after filtering by template criteria"
              )
            );
          }

          if (TemplateData.validateWords) {
            const result = TemplateData.validateWords(filteredWords, template);

            if (result.error) {
              throw new CustomError(
                `${template.activityTemplateName}: ${result.error}`
              );
            }
          }

          console.log(`Start generating ${template.activityTemplateName}`);

          const assets = [];

          const getHelpHtmlAndAudio = loc => {
            const html = helpLocaleTranslates[loc.code](
              template.helpStrings.wordlist,
              "",
              false,
              true
            );

            const templateNameWithoutSpaces = template.activityTemplateName
              .split(" ")
              .join("");

            const helpAudioFile = `${loc.name}/${templateNameWithoutSpaces}.mp3`;
            const src = `Audio/Generic/WordlistActivityHelp/${helpAudioFile}`;

            const isHelpAudioMissed = !!missedAssets.filter(
              path => path.indexOf(src) !== -1
            ).length;

            let audio = "";

            if (!isHelpAudioMissed) {
              audio = `${templateNameWithoutSpaces}-${loc.code}.mp3`;
              // Add help if its not missing
              assets.push({
                src,
                filename: audio
              });
            }

            return [html, audio];
          };

          let multiLocaleHelp = null;
          let activityHelpLocale = "";
          let helpAudio = "";
          let helpHtml = "";

          if (isMultiLocaleHelp) {
            multiLocaleHelp = {
              defaultLocale
            };

            helpLocaleObjects.forEach(loc => {
              const [helpHtml, helpAudio] = getHelpHtmlAndAudio(loc);
              multiLocaleHelp[loc.code] = { helpAudio, helpHtml };
            });
          } else {
            [helpHtml, helpAudio] = getHelpHtmlAndAudio(helpLocaleObjects[0]);
            activityHelpLocale = helpLocaleObjects[0].code;
          }

          const data = {
            isGenerated: true,
            // useDefaultAudioHelp: true,
            templateId: template._id,
            templateAlias: template.alias,
            options: template.defaultOptions || [],
            activityLocale,
            multiLocaleHelp,
            helpLocale: activityHelpLocale,
            helpHtml,
            helpAudio
          };

          delete data.options.maxFontSize;

          // Remove parenthesis from words and related words
          const wordsWithoutParenthesis = filteredWords.map(w => ({
            ...w,
            word: removeParenthesis(w.word),
            relatedWord: w.relatedWord && removeParenthesis(w.relatedWord)
          }));

          const result = TemplateData.generateActivityFromWords({
            words: wordsWithoutParenthesis,
            includeDefinitionAudio,
            template
          });

          if (result.error) {
            throw new CustomError(
              `${template.activityTemplateName}: ${result.error}`
            );
          }

          const {
            gameData,
            definitionsAudio = [],
            wordsAudio = [],
            wordsImages = []
          } = result;

          data.gameData = gameData;

          // Assets
          definitionsAudio.forEach(audio => {
            if (!audio) return;

            const firstLetter = audio.src[0].toUpperCase();

            assets.push({
              src: `Audio/Generic/WordsDB/${activityLocaleObj.name}/Definitions/${firstLetter}/${audio.src}`,
              filename: audio.filename
            });
          });

          wordsAudio.forEach(audio => {
            if (!audio) return;
            console.log(audio);

            const firstLetter = audio.src[0].toUpperCase();

            assets.push({
              src: `Audio/Generic/WordsDB/${activityLocaleObj.name}/Words/${firstLetter}/${audio.src}`,
              filename: audio.filename
            });
          });

          wordsImages.forEach(image => {
            if (!image) return;

            assets.push({
              src: `Images/WordlistPictures/${image.src}`,
              filename: image.filename
            });
          });

          activities.push({
            assets,
            data,
            dbData: {
              activityName: activityLocaleTranslate(template[wordlistName]),
              templateId: template._id,
              isValid: true
            }
          });
        });

        console.log("Generated Activities:", activities);

        return axiosAPI.post(
          "/api/flink-make/wordlists/generate-activity-group",
          {
            activities,
            wordlist,
            activityGroupName
          }
        );
      })
      .then(result => {
        if (result.data.success) {
          const { activityGroup, copyAssetsResults } = result.data;

          const missedAssets = copyAssetsResults
            .filter(asset => !asset.success)
            .map(asset => asset.key);

          let successMessage = translate(271, "Success!");
          console.log("activityGroup", activityGroup);

          if (missedAssets.length) {
            successMessage += `<br><br><b>Missed Assets:</b><br>${missedAssets.join(
              "<br>"
            )}`;
          }

          dispatch(
            showInfo({
              message: successMessage,
              html: true
            })
          );
        }
      })
      .catch(err => {
        if (!err) return;

        console.log(err);

        if (err.custom) {
          dispatch(showError({ message: err.message }));
        }
      });
  });

/**
 * @param {Object}    params
 * @param {Object}    params.activeGroup             DB object.
 * @param {String}    params.activityLocale       activity locale code.
 * @param {Array}     params.helpLocale           help locales code.
 * @param {Array}     params.selectedTemplatesIDs Array of Activity Templates IDs.
 * @param {Boolean}   params.wordsWithImages
 * @param {Array}     params.activeGroupFiles     Array of Words Files.
 */
export const checkWordAgs = params => (dispatch, getState) =>
  withLoading(dispatch, async () => {
    const {
      activeGroup,
      selectedTemplatesIDs,
      wordsWithImages,
      activeGroupFiles
    } = params;

    const {
      common: { strings, activityTemplates },
      status: { lang: currentLang }
    } = getState();

    const translate = getTranslateFunc(strings, currentLang.code);
    const wordsWithPicture = activeGroup.multilanguageWords.map((word) => {
      if (word.wordImage) word.hasPicture = true;
      return word;
    });
    let passedWords = wordsWithPicture;
    console.log('checkWordAgs passedWords:', passedWords);
    const selectedTemplates = selectedTemplatesIDs.map(_id =>
      _.find(activityTemplates, { _id })
    );

    // 1. Check if all words have images (only if "wordsWithImages" is checked)
    const wordsWithoutImages = [];

    if (wordsWithImages) {
      passedWords.forEach(
        w => !w.wordImage && wordsWithoutImages.push(w.word)
      );
    }

    if (wordsWithoutImages.length) {
      // If some words haven't DB records then show error
      throw new CustomError(
        translate(
          0,
          "Following words have not associated image with it: "
        ) + wordsWithoutImages.join(", ")
      );
    }

    // 2. Check if all selected templates has Generating workflow in the code
    const templatesWithoutGeneratingFunc = [];

    selectedTemplates.forEach(template => {
      const TemplateData = ActivityTemplates[template.alias];

      if (!TemplateData.generateActivityFromWords) {
        templatesWithoutGeneratingFunc.push(template.activityTemplateName);
      }
    });

    if (templatesWithoutGeneratingFunc.length) {
      // If some templates dont have generating func -> show error
      throw new CustomError(
        translate(
          263,
          "Following templates dont have generating functions: "
        ) + templatesWithoutGeneratingFunc.join(", ")
      );
    }

    // 3. Validate words for each template
    const validateWordsErrors = [];

    selectedTemplates.forEach(template => {
      const { minWords } = template;

      const filteredWords = _filterWordsByTemplateCriteria(
        passedWords,
        template,
        true
      );

      if (filteredWords.length < minWords) {
        validateWordsErrors.push({
          template: template.activityTemplateName,
          message: translate(
            0,
            "Not enough words after filtering by template criteria"
          )
        });

        return;
      }

      const TemplateData = ActivityTemplates[template.alias];

      const result = TemplateData.validateWords(filteredWords, template);

      if (result.error) {
        validateWordsErrors.push({
          template: template.activityTemplateName,
          message: result.error
        });
      }
    });

    if (validateWordsErrors.length) {
      throw new CustomError(
        validateWordsErrors
          .map(err => `${err.template}: ${err.message}`)
          .join("; ")
      );
    }

    // 4. Check if words have assets
    const allAssets = activeGroupFiles;

    // Send assets for check
    return axiosAPI.post(
      "api/flink-make/wordlists/check-word-ags-assets",
      allAssets
    )
    .then(response => {
      const missedAssets = response.data
        .filter(res => !res.success)
        .map(res => res.key);

      return Promise.resolve({ words: passedWords, missedAssets });
    })
    .catch(err => {
      console.log(err);

      if (err.custom) {
        dispatch(showError({ message: err.message }));
      }

      return Promise.resolve(null);
    });
  });

/**
 * @param {Object}    params
 * @param {Object}    params.activeGroup               DB object.
 * @param {String}    params.activityLocale         activity locale code.
 * @param {Array}     params.helpLocale             help locales code.
 * @param {String}    params.defaultLocale          Locale Code. If help locales > 1, then default locale is required
 * @param {Array}     params.selectedTemplatesIDs   Array of Activity Templates IDs.
 * @param {Boolean}   params.includeDefinitionAudio
 * @param {Boolean}   params.wordsWithImages
 * @param {Array}     params.activeGroupFiles       Array of Words Files.
 */
export const generateWordActivityGroup = params => (dispatch, getState) =>
  withLoading(dispatch, () => {
    const {
      activeGroup, // object
      activityLocale, // string locale code
      //helpLocale, // array locale code
      //defaultLocale,
      selectedTemplatesIDs, // array of IDs
      includeDefinitionAudio, // boolean
      activeGroupFiles,
      words
    } = params;

    const {
      common: { strings, activityTemplates, locales },
      status: { lang: currentLang }
    } = getState();

    const activities = [];
    const translate = getTranslateFunc(strings, currentLang.code);

    const activityLocaleObj = _.find(locales, { code: activityLocale });
    const activityLocaleTranslate = getTranslateFunc(
      strings,
      activityLocaleObj.code
    );

    /*const helpLocaleObjects = _.filter(locales, loc =>
      helpLocale.includes(loc.code)
    );*/

    /*const helpLocaleTranslates = {};

    helpLocale.forEach(
      code => (helpLocaleTranslates[code] = getTranslateFunc(strings, code))
    );*/

    //const isMultiLocaleHelp = helpLocaleObjects.length > 1;

    const selectedTemplates = selectedTemplatesIDs.map(_id =>
      _.find(activityTemplates, { _id })
    );

    //let missedAssets = [];

    const filesMap = activeGroupFiles.map(item => {
      const fileString = item.split(`/${activityLocale}/`)[1];
      const element = {};
      if (fileString.split('/')[0] === 'Audio') {
        element['Audio'] = fileString.split('/')[1];
        element.src = item;
      }
      if (fileString.split('/')[0] === 'DefinitionAudio') {
        element['DefinitionAudio'] = fileString.split('/')[1];
        element.src = item;
      }
      if (fileString.split('/')[0] === 'Image') {
        //element['Image'] = fileString.split('/')[1];
        const temp = fileString.split('/')[1];
        if (temp.indexOf('_') !== -1)
          element['Image'] = `${temp.split('_')[0]}.${temp.split('.')[1]}`;
        else
          element['Image'] = temp;
          element.src = item;
      }
      return element;
    });

    console.log('generateWordActivityGroup filesMap:', filesMap);       

    selectedTemplates.forEach(template => {
      const TemplateData = ActivityTemplates[template.alias];

      const { minWords } = template;

      const filteredWords = _filterWordsByTemplateCriteria(words, template);

      if (filteredWords.length < minWords) {
        throw new CustomError(
          translate(
            0,
            "Not enough words after filtering by template criteria"
          )
        );
      }

      if (TemplateData.validateWords) {
        const result = TemplateData.validateWords(filteredWords, template);

        if (result.error) {
          throw new CustomError(
            `${template.activityTemplateName}: ${result.error}`
          );
        }
      }

      console.log(`Start generating ${template.activityTemplateName}`);

      const assets = [];

      /*const getHelpHtmlAndAudio = loc => {
        const html = helpLocaleTranslates[loc.code](
          template.helpStrings.wordlist,
          "",
          false,
          true
        );

        const templateNameWithoutSpaces = template.activityTemplateName
          .split(" ")
          .join("");

        const helpAudioFile = `${loc.name}/${templateNameWithoutSpaces}.mp3`;
        const src = `Audio/Generic/WordlistActivityHelp/${helpAudioFile}`;

        const isHelpAudioMissed = !!missedAssets.filter(
          path => path.indexOf(src) !== -1
        ).length;

        let audio = "";

        if (!isHelpAudioMissed) {
          audio = `${templateNameWithoutSpaces}-${loc.code}.mp3`;
          // Add help if its not missing
          assets.push({
            src,
            filename: audio
          });
        }

        return [html, audio];
      };

      let multiLocaleHelp = null;
      let activityHelpLocale = "";
      let helpAudio = "";
      let helpHtml = "";

      if (isMultiLocaleHelp) {
        multiLocaleHelp = {
          defaultLocale
        };

        helpLocaleObjects.forEach(loc => {
          const [helpHtml, helpAudio] = getHelpHtmlAndAudio(loc);
          multiLocaleHelp[loc.code] = { helpAudio, helpHtml };
        });
      } else {
        [helpHtml, helpAudio] = getHelpHtmlAndAudio(helpLocaleObjects[0]);
        activityHelpLocale = helpLocaleObjects[0].code;
      }*/

      const data = {
        isGenerated: true,
        // useDefaultAudioHelp: true,
        templateId: template._id,
        templateAlias: template.alias,
        options: template.defaultOptions || [],
        activityLocale,
        multiLocaleHelp: null,
        helpLocale: "",
        helpString: template.helpStrings.wordlist,
        helpHtml: "",
        helpAudio: ""
      };

      delete data.options.maxFontSize;

      // Remove parenthesis from words and related words
      const wordsWithoutParenthesis = filteredWords.map(w => ({
        ...w,
        word: removeParenthesis(w.word),
        relatedWord: w.relatedWord && removeParenthesis(w.relatedWord)
      }));

      const result = TemplateData.generateActivityFromWords({
        words: wordsWithoutParenthesis,
        includeDefinitionAudio,
        template
      });

      if (result.error) {
        throw new CustomError(
          `${template.activityTemplateName}: ${result.error}`
        );
      }

      const {
        gameData,
        definitionsAudio = [],
        wordsAudio = [],
        wordsImages = []
      } = result;

      data.gameData = gameData;         
console.log('generateWordActivityGroup result:', result);
      // Assets
      definitionsAudio.forEach(audio => {
        if (!audio) return;

        const file = filesMap.find(({ DefinitionAudio }) => DefinitionAudio === audio.src);

        assets.push({
          src: file.src,
          filename: audio.filename
        });
      });

      wordsAudio.forEach(audio => {
        if (!audio) return;
        console.log(audio);

        const file = filesMap.find(({ Audio }) => Audio === audio.src);

        assets.push({
          src: file.src,
          filename: audio.filename
        });
      });

      wordsImages.forEach(image => {
        if (!image) return;

        const file = filesMap.find(({ Image }) => Image === image.src);

        assets.push({
          src: file.src,
          filename: image.filename
        });
      });

      activities.push({
        assets,
        data,
        dbData: {
          activityName: activityLocaleTranslate(template['wordlistName1']),
          templateId: template._id,
          isValid: true
        }
      });
    });

    console.log("Generated Activities:", activities);
    return axiosAPI.post(
      "/api/flink-make/wordlists/generate-word-activities",
      {
        activities,
        activeGroup
      }
    )
    .then(result => {
      if (result.data.success) {
        const { activityGroup, copyAssetsResults } = result.data;

        const missedAssets = copyAssetsResults
          .filter(asset => !asset.success)
          .map(asset => asset.key);

        let successMessage = translate(271, "Success!");
        console.log("generateWordActivityGroup activityGroup", activityGroup);

        if (missedAssets.length) {
          successMessage += `<br><br><b>Missed Assets:</b><br>${missedAssets.join(
            "<br>"
          )}`;
        }
        
        return [activityGroup, successMessage];
      }
    })
    .catch(err => {
      if (!err) return;

      console.log(err);

      if (err.custom) {
        dispatch(showError({ message: err.message }));
      }
    });
  });
