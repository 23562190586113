import React from 'react';

import _ from 'lodash';

// s3
import { audioURL } from 'config';

// redux
import { useSelector } from 'react-redux';

export default function useGetStringAudio(localeOverride) {
  const { 
    strings, locales, 
    currentActivity, editingActivity, 
    solutionLocale 
  } = useSelector((state) => ({
    currentActivity: state.game.currentActivity,
    editingActivity: state.flinkMake.activity.editingActivity,
    solutionLocale: state.flinkPlay.solutionLocale || state.status.lang,
    strings: state.common.strings,
    locales: state.common.locales,
  }));

  const locale = React.useMemo(() => {
    const code = localeOverride || 
      editingActivity?.activity?.currentLanguageVersion?.locale ||
      currentActivity?.activity.locale ||
      editingActivity?.activity.locale || 
      solutionLocale?.code || 'en';
    return _.find(locales, { code });
  }, [
    locales, localeOverride, 
    solutionLocale, 
    currentActivity?.activity.locale,
    editingActivity?.activity.locale,
    editingActivity?.activity?.currentLanguageVersion?.locale
  ]);

  return React.useCallback((stringNumber, getFullPath = false) => {
    const search = _.find(strings, { stringNumber: +stringNumber });
    const path = getFullPath ?
      `${audioURL}/Generic/Strings/${locale.name}/` :
      `Audio/Generic/Strings/${locale.name}`;

    const audio = _.get(search, `data.${locale.code}.stringAudio`);

    // debugger;

    return { stringNumber, audio, path };
  }, [strings, locale]);
}
