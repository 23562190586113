import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";

import { withGetStringAudio } from "components/hocs";
import {
  changeActivityData,
  removeContentFiles
} from "actions/flinkMakeActions/activity";
import {
  WordAgsAddAudio,
  Editor,
} from "components/flink-components";
import { StringAssignField } from "components/form-components";
import classes from "./WordAgsHelpModal.module.scss";
import DraggableModal from "../DraggableModal/DraggableModal";

class WordAgsHelpModal extends Component {
  constructor(props) {
    super(props);    

    this.state = {
      showEditor: true,
      editorRawText: "",
      helpString: "",
      helpAudio: "",
      helpHtml: "",
      showHelp: false
    };
  }

  componentDidUpdate(prevProps) {
    const { activeGroup, show, currentLang } = this.props;
    const { helpAudio, showHelp } = this.state;

    if (activeGroup?.multilangWordsHelp !== prevProps.activeGroup?.multilangWordsHelp
      || prevProps.currentLang !== currentLang
      ) {
      this.reloadEditor();  
      this.setFirstHelpValue();    
    }

    if (show && activeGroup && !showHelp)
      this.setFirstHelpValue();

    console.log('WordAgsHelpModal helpAudio',helpAudio)
    console.log('WordAgsHelpModal currentLang',currentLang)
  }

  editorRef = React.createRef();

  reloadEditor = () => {   
    this.setState({ showEditor: false }, () => {           
      this.setState({ showEditor: true });
    });
  };

  setFirstHelpValue = () => {
    const { activeGroup, currentLang } = this.props;
    const { helpAudio, helpString } = !activeGroup?.multilangWordsHelp?.[currentLang]
    ? (!activeGroup?.multilangWordsHelp?.['en'] 
      ? { helpAudio:"", helpString:"" } 
      : {helpAudio:"", helpString: activeGroup?.multilangWordsHelp?.['en']?.helpString}
      )

    : activeGroup?.multilangWordsHelp?.[currentLang];
    
    if (helpString) {     
      this.changeHelpText(helpString);
      this.setState({
        showHelp: true,
        helpAudio
      });
    }
    else {
      this.setState({
        helpString: "",
        helpAudio: "",
        helpHtml: "",
        editorRawText: "",
        showHelp: true,
      });
    }
  }

  changeHelp = (type, data) => {
    this.setState({ [type]: data });   
  };

  saveChangeHelp = () => {
    const { activeGroup, currentLang, onCreate, onClose } = this.props;
    const { helpAudio, helpString } = this.state;

    const updatedActiveGroup = {
      ...activeGroup,
      multilangWordsHelp: {
        ...activeGroup.multilangWordsHelp, 
        [currentLang]: {                  
          helpAudio,
          helpString,
        },
      },
    };

    onCreate(updatedActiveGroup);

    this.setState({
      helpString: "",
      helpAudio: "",
      helpHtml: "",
      editorRawText: "",
      showHelp: false,
    });

    onClose();
  };

  changeHelpText = (string) => {
    const { translate, currentLang } = this.props;
    const translated = (string && translate(string, '', false, true, currentLang)) || "";
    this.changeHelp("helpHtml", this.changeRawHtml(translated))
    this.changeHelp("helpString", string);  
    if (this.editorRef.current)              
      this.editorRef.current.setEditorContent(this.changeRawHtml(translated));
  }

  getWordsFromVariable = () => {
    const { activeGroup, currentLang, otherLang } = this.props;
    if (!activeGroup?.multilanguageWords?.length) return false;
    let wordsInWordlist = activeGroup?.multilanguageWords?.map(item => {
      if (!otherLang) {
        if (item.locale === currentLang) return item.word;
        else return '';
      }
      else {
        if (item?.otherLocales?.[currentLang]) 
          return item?.otherLocales?.[currentLang]?.word;
        else return '';
      }
    });
    wordsInWordlist = wordsInWordlist.filter(element => !!element);
    return wordsInWordlist;
  }

  changeRawText = (text) => {
    let newText = '';
    const wordsInWordlist = this.getWordsFromVariable();
    if (wordsInWordlist && text)
      newText = text.replace('{{wordsInWordlist}}', wordsInWordlist.join(', '));
    this.setState({ editorRawText: `${newText}` })
  }

  changeRawHtml = (helpHtml) => {
    let html = '';
    const wordsInWordlist = this.getWordsFromVariable();
    if (wordsInWordlist && helpHtml)
      html = helpHtml.replace('{{wordsInWordlist}}', wordsInWordlist.join(', '));
    return html;
  }


  render() {
    const { 
      showEditor, 
      editorRawText,
      helpString,
      helpAudio,
      helpHtml
    } = this.state;

    const {
      activeGroup,
      translate,
      currentLang,
      show,
      onClose,
      helpFilesPath,
    } = this.props;

    return (
      <DraggableModal
        show={show}
        onClose={onClose}
        title={translate(0, "Add or Edit Help")}
        withOverlay
      >
        <div className={classes.wrapper}>         
          <div className={classes.stringAssign}>
            
            <button
              onClick={this.saveChangeHelp}
              className={classes.generateBtn}
              disabled={!helpString}
            >
              {translate(0, "Save")}
            </button>
            
            <StringAssignField
              value={helpString}
              audio={helpAudio}
              changeAudioHandler={filename => {
                if (filename !== 'INHERITED') 
                  this.changeHelp("helpAudio", filename)
              }}
              changeTextHandler={string => this.changeHelpText(string)}
              localeOverride={currentLang}
              forHelp
            />
          </div>

          <div className={classes.editorWrapper}>
            {showEditor && (
              <Editor
                ref={this.editorRef}
                initialValue={this.changeRawHtml(helpHtml)}
                className={classes.editor}
                changeHandler={html => this.changeHelp("helpHtml", this.changeRawHtml(html))}
                changeHandlerRaw={text => this.changeRawText(text)}
                readOnly={!!helpString}
              />
            )}
          </div>

          {activeGroup && (
            <WordAgsAddAudio
              current={helpAudio}
              helpFilesPath={helpFilesPath}
              onChangeAudio={filename => {
                if (filename !== 'INHERITED')
                  this.changeHelp("helpAudio", filename);
              }}
              associatedText={editorRawText}
              readOnly={!!helpString}
              useFileBucket={!!helpString}
              activeGroupId={activeGroup._id}
              locale={currentLang}
            />
          )}
        
        </div>
      </DraggableModal>
    );
  }
}

WordAgsHelpModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  activeGroup: PropTypes.object.isRequired,
  currentLang: PropTypes.string.isRequired,
  changeActivityData: PropTypes.func.isRequired,
  removeContentFiles: PropTypes.func.isRequired,
  getStringAudio: PropTypes.func.isRequired,
  helpFilesPath: PropTypes.string.isRequired,
  otherLang: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ status }) => ({
  translate: status.translate
});

export default compose(
  withGetStringAudio,
  connect(mapStateToProps, {
    changeActivityData,
    removeContentFiles
  })
)(WordAgsHelpModal);
